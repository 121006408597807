import logo from "assets/images/brands/434bb57c-88d9-4b0d-a0c8-fb94eebf144b.png";
import nightLogo from "assets/images/brands/af3e8578-46bf-43d4-96a2-aca7df37e940.png";

export const clientName = "Tradefx Pte.Ltd";
export const developedBy = "Exinitic";

export const imagesUrl = process.env.REACT_APP_API_IMAGES_URL;
export const cpUrl = process.env.REACT_APP_CP_URL;
export const apiUrl = process.env.REACT_APP_API_URL;
export const companyName =  "Tradefx Pte.Ltd";
export const sidebarLogo = nightLogo;
export const mainLogo = nightLogo;
export const lightLogo = nightLogo;
export const darkMode = nightLogo;